// 维保
import store from '@/store'
import http from '@/utils/http'

let preApi = '';
if(store.state.mock && process.env.NODE_ENV == 'development') {
  preApi = '/mock';
}

//添加维保单位任务信息
export function addMaintenanceUnitTask(params) {
  return http({
    url: `${preApi}/maintenance/addMaintenanceUnitTask`,
    method: "post",
    data: params
  })
}
//删除维保单位任务信息
export function deleteMaintenanceUnitTask(params) {
  return http({
    url: `${preApi}/maintenance/deleteMaintenanceUnitTask`,
    method: "post",
    data: params
  })
}
//修改维保单位任务信息
export function modifyMaintenanceUnitTask(params) {
  return http({
    url: `${preApi}/maintenance/modifyMaintenanceUnitTask`,
    method: "post",
    data: params,
  })
}
//根据条件获取维保单位任务信息列表
export function getMaintenanceUnitTaskListByCondition(params) {
  return http({
    url: `${preApi}/maintenance/getMaintenanceUnitTaskListByCondition`,
    method: "post",
    data: params,
  })
}
//根据条件获取维保单位任务列表
export function getMaintenanceUnitTaskList(params) {
  return http({
    url: `${preApi}/maintenance/getMaintenanceUnitTaskList`,
    method: "post",
    data: params,
  })
}
//导出设施设备excel
export function exportMaintenanceUnitTaskExcel(params) {
  return http({
    url: `${preApi}/maintenance/exportMaintenanceUnitTaskExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

//添加维保计划信息
export function addMaintenanceSchedule(params) {
  return http({
    url: `${preApi}/maintenance/addMaintenanceSchedule`,
    method: "post",
    data: params
  })
}
//删除维保计划信息
export function deleteMaintenanceSchedule(params) {
  return http({
    url: `${preApi}/maintenance/deleteMaintenanceSchedule`,
    method: "post",
    data: params
  })
}
//修改维保计划信息
export function modifyMaintenanceSchedule(params) {
  return http({
    url: `${preApi}/maintenance/modifyMaintenanceSchedule`,
    method: "post",
    data: params,
  })
}

//获取维保计划列表查询条件
export function getMaintenanceScheduleConditionList(params) {
  return http({
    url: `${preApi}/maintenance/getMaintenanceScheduleConditionList`,
    method: "post",
    data: params,
  })
}
//根据条件获取维保计划列表
export function getMaintenanceScheduleListByCondition(params) {
  return http({
    url: `${preApi}/maintenance/getMaintenanceScheduleListByCondition`,
    method: "post",
    data: params,
  })
}
//根据条件获取维保计划对照表列表
export function getMaintenanceScheduleContrastListByCondition(params) {
  return http({
    url: `${preApi}/maintenance/getMaintenanceScheduleContrastListByCondition`,
    method: "post",
    data: params,
  })
}
//导出维保计划excel
export function exportMaintenanceScheduleExcel(params) {
  return http({
    url: `${preApi}/maintenance/exportMaintenanceScheduleExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}
//批量生成年度保养计划
export function generateYearPlan(params) {
  return http({
    url: `${preApi}/maintenance/generateYearPlan`,
    method: "post",
    data: params,
  })
}

//添加维保任务信息
export function addMaintenanceOrder(params) {
  return http({
    url: `${preApi}/maintenance/addMaintenanceOrder`,
    method: "post",
    data: params
  })
}
//删除维保任务信息
export function deleteMaintenanceOrder(params) {
  return http({
    url: `${preApi}/maintenance/deleteMaintenanceOrder`,
    method: "post",
    data: params
  })
}
// 撤销维修工单
export function cancelMaintenanceOrder(params) {
  return http({
    url: `${preApi}/maintenance/cancelMaintenanceOrder`,
    method: "post",
    data: params
  })
}
//修改维保任务信息
export function modifyMaintenanceOrder(params) {
  return http({
    url: `${preApi}/maintenance/modifyMaintenanceOrder`,
    method: "post",
    data: params,
  })
}
//根据条件获取维保任务列表
export function getMaintenanceOrderListByCondition(params) {
  return http({
    url: `${preApi}/maintenance/getMaintenanceOrderListByCondition`,
    method: "post",
    data: params,
  })
}
export function exportMaintenanceOrderListExcel(params) {
  return http({
    url: `${preApi}/maintenance/exportMaintenanceOrderListExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}
//维保人员根据条件获取维保任务列表
export function getMaintenanceOrderListByMaintenanceGroupName(params) {
  return http({
    url: `${preApi}/maintenance/getMaintenanceOrderListByMaintenanceGroupName`,
    method: "post",
    data: params,
  })
}
// 查询工单详情
export function getMaintenanceOrderInfoListByCondition(params) {
  return http({
    url: `${preApi}/maintenance/getMaintenanceOrderInfoListByCondition`,
    method: "post",
    data: params
  })
}
// 查询工单统计
export function getMaintenanceOrderInfoStatistics(params) {
  return http({
    url: `${preApi}/maintenance/getMaintenanceOrderInfoStatistics`,
    method: "post",
    data: params
  })
}
// 批量导出设施设备保养分析报表
export function exportMaintenanceOrderInfoExcel(params) {
  return http({
    url: `${preApi}/maintenance/exportMaintenanceOrderInfoExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

// 查询维保单位列表
export function getMaintenanceUnitListByCondition(params) {
  return http({
    url: `${preApi}/maintenance/getMaintenanceUnitListByCondition`,
    method: "post",
    data: params
  })
}
// 查询工单详情
export function getMaintenanceOrderInfoByOrderId(params) {
  return http({
    url: `${preApi}/maintenance/getMaintenanceOrderInfoByOrderId`,
    method: "post",
    data: params
  })
}
// 提交工单流程
export function commitWorkflow(params) {
  return http({
    url: `${preApi}/maintenance/commitWorkflow`,
    method: "post",
    data: params
  })
}
// 查询工单流程
export function getWorkflowByOrderId(params) {
  return http({
    url: `${preApi}/maintenance/getWorkflowByOrderId`,
    method: "post",
    data: params
  })
}
// 提交维保检查项信息
export function commitMaintenanceItemResult(params) {
  return http({
    url: `${preApi}/maintenance/commitMaintenanceItemResult`,
    method: "post",
    data: params
  })
}
// 查询维保检查项信息
export function getMaintenanceItemRecordListByOrderId(params) {
  return http({
    url: `${preApi}/maintenance/getMaintenanceItemRecordListByOrderId`,
    method: "post",
    data: params
  })
}
// 提交维保图片信息列表
export function commitMaintenancePic(params) {
  return http({
    url: `${preApi}/maintenance/commitMaintenancePic`,
    method: "post",
    data: params
  })
}
// 查询维保图片信息列表
export function getMaintenancePicByOrderId(params) {
  return http({
    url: `${preApi}/maintenance/getMaintenancePicByOrderId`,
    method: "post",
    data: params
  })
}
// 维保项目--添加
export function addMaintenanceItem(params) {
  return http({
    url: `${preApi}/maintenance/addMaintenanceItem`,
    method: "post",
    data: params
  })
}
// 维保项目--修改
export function modifyMaintenanceItem(params) {
  return http({
    url: `${preApi}/maintenance/modifyMaintenanceItem`,
    method: "post",
    data: params
  })
}
// 维保项目--删除
export function deleteMaintenanceItem(params) {
  return http({
    url: `${preApi}/maintenance/deleteMaintenanceItem`,
    method: "post",
    data: params
  })
}
// 维保项目--列表
export function getMaintenanceItemListByCondition(params) {
  return http({
    url: `${preApi}/maintenance/getMaintenanceItemListByCondition`,
    method: "post",
    data: params
  })
}
// 保养周期
export function getMaintenancePeriodByScheduleType(params) {
  return http({
    url: `${preApi}/maintenance/getMaintenancePeriodByScheduleType`,
    method: "post",
    data: params
  })
}