export const engineerInspectionItemStatus = {
  '1': '正常',
  '2': '无',
  '3': '现场处理好',
  '4': '已报修',
  '5': '已报告',
}

export const resultCodesList = [
  {"dickey":'1',"dicvalue": '正常'},
  {"dickey":'2',"dicvalue": '无'},
  {"dickey":'3',"dicvalue": '现场处理好'},
  {"dickey":'4',"dicvalue": '已报修'},
  {"dickey":'5',"dicvalue": '已报告'},
]

export const resultCodes = {
  '1': '正常',
  '2': '无',
  '3': '现场处理好',
  '4': '已报修',
  '5': '已报告',
}

export const InspectionException = {
  "0": "正常",
  "1": "异常",
}
export const InspectionExceptionColors = {
  "0": "green",
  "1": "red",
}


export const pointTypes = {
  '0001': '园区',
  '0002': '大堂',
  '0003': '楼层',
  '0004': '天面与避难层',
  '0005': '地下室',
  '0006': '商铺区',
  '0007': '活动场地',
  '0008': '设备区',
  '0009': '车厂出入口',
  '0010': '电动车停放点',
  '0011': '垃圾转运站',
  '0012': '其他',
}