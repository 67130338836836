//员工工作状态查询
import store from '@/store'
import http from '@/utils/http'

let preApi = '';
if(store.state.mock && process.env.NODE_ENV == 'development') {
  preApi = '/mock';
}

//维保人员工作状态
export function getMaintenancePersonListByCondition(params) {
  return http({
    url: `${preApi}/employeeStatus/getMaintenancePersonListByCondition`,
    method: "post",
    data: params,
  })
}
//导出维保人员工作状态
export function exportMaintenancePersonExcel(params) {
  return http({
    url: `${preApi}/employeeStatus/exportMaintenancePersonExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

//调度员工作状态
export function getYardPersonListByCondition(params) {
  return http({
    url: `${preApi}/employeeStatus/getYardPersonListByCondition`,
    method: "post",
    data: params,
  })
}
//导出调度员工作状态
export function exportYardPersonExcel(params) {
  return http({
    url: `${preApi}/employeeStatus/exportYardPersonExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}
//调度员工作状态
export function getYardPersonEventRecordListByCondition(params) {
  return http({
    url: `${preApi}/employeeStatus/getYardPersonEventRecordListByCondition`,
    method: "post",
    data: params,
  })
}
//导出调度员工作状态
export function exportYardPersonEventRecordExcel(params) {
  return http({
    url: `${preApi}/employeeStatus/exportYardPersonEventRecordExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

//调度员巡查状态
export function getYardPatrolListByCondition(params) {
  return http({
    url: `${preApi}/employeeStatus/getYardPatrolListByCondition`,
    method: "post",
    data: params,
  })
}
//导出调度员巡查状态
export function exportYardPatrolExcel(params) {
  return http({
    url: `${preApi}/employeeStatus/exportYardPatrolExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

//人工巡查状态
export function getManualPatrolListByCondition(params) {
  return http({
    url: `${preApi}/employeeStatus/getManualPatrolListByCondition`,
    method: "post",
    data: params,
  })
}
//导出人工巡查状态
export function exportManualPatrolExcel(params) {
  return http({
    url: `${preApi}/employeeStatus/exportManualPatrolExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

//环境作业人员巡查状态
export function getEnvironmentPersonListByCondition(params) {
  return http({
    url: `${preApi}/employeeStatus/getEnvironmentPersonListByCondition`,
    method: "post",
    data: params,
  })
}
//导出环境作业人员巡查状态
export function exportEnvironmentPersonExcel(params) {
  return http({
    url: `${preApi}/employeeStatus/exportEnvironmentPersonExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}


//维保人员工作状态
export function getNoWorkMaintenancePersonListByCondition(params) {
  return http({
    url: `${preApi}/employeeStatus/getNoWorkMaintenancePersonListByCondition`,
    method: "post",
    data: params,
  })
}
//导出维保人员工作状态
export function exportNoWorkMaintenancePersonExcel(params) {
  return http({
    url: `${preApi}/employeeStatus/exportNoWorkMaintenancePersonExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

//调度员工作状态
export function getNoWorkYardPersonListByCondition(params) {
  return http({
    url: `${preApi}/employeeStatus/getNoWorkYardPersonListByCondition`,
    method: "post",
    data: params,
  })
}
//导出调度员工作状态
export function exportNoWorkYardPersonExcel(params) {
  return http({
    url: `${preApi}/employeeStatus/exportNoWorkYardPersonExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

//调度员巡查状态
export function getNoWorkYardPatrolListByCondition(params) {
  return http({
    url: `${preApi}/employeeStatus/getNoWorkYardPatrolListByCondition`,
    method: "post",
    data: params,
  })
}
//导出调度员巡查状态
export function exportNoWorkYardPatrolExcel(params) {
  return http({
    url: `${preApi}/employeeStatus/exportNoWorkYardPatrolExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

//人工巡查状态
export function getNoWorkManualPatrolListByCondition(params) {
  return http({
    url: `${preApi}/employeeStatus/getNoWorkManualPatrolListByCondition`,
    method: "post",
    data: params,
  })
}
//导出人工巡查状态
export function exportNoWorkManualPatrolExcel(params) {
  return http({
    url: `${preApi}/employeeStatus/exportNoWorkManualPatrolExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

//环境作业人员巡查状态
export function getNoWorkEnvironmentPersonListByCondition(params) {
  return http({
    url: `${preApi}/employeeStatus/getNoWorkEnvironmentPersonListByCondition`,
    method: "post",
    data: params,
  })
}
//导出环境作业人员巡查状态
export function exportNoWorkEnvironmentPersonExcel(params) {
  return http({
    url: `${preApi}/employeeStatus/exportNoWorkEnvironmentPersonExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}