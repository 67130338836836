<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="1000" :centered="true" :body-style="modalBodyStyle">
    <template slot="footer">
      <a-button type="primary" @click="modalVisible=false">关闭</a-button>
    </template>
    <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns detail-form-bg" :model="formData" :label-col="{span:9}" :wrapper-col="{span:14}">
<!--      <a-tabs type="card">-->
<!--        <a-tab-pane key="1" tab="作业信息">-->
          <h3 style="font-weight: bold; color: #333;">作业信息</h3>
          <a-form-model-item label="作业类型" prop="work_type">
            <span>{{formData.work_type}}</span>
          </a-form-model-item>
          <a-form-model-item label="作业子类型" prop="work_child_type">
            <span>{{formData.work_child_type}}</span>
          </a-form-model-item>
          <a-form-model-item label="作业人员" prop="work_person">
            <span>{{formData.work_person}}</span>
          </a-form-model-item>
          <a-form-model-item label="作业时间" prop="check_start_time">
            <span>{{formData.work_time ? moment(formData.work_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-form-model-item>
          <a-form-model-item label="作业点名称" prop="point_name">
            <span>{{formData.point_name}}</span>
          </a-form-model-item>
          <a-form-model-item label="作业单位" prop="unit_name">
            <span>{{formData.unit_name}}</span>
          </a-form-model-item>
          <a-form-model-item label="区域" prop="region">
            <span>{{formData.region}}</span>
          </a-form-model-item>
          <a-form-model-item label="楼层" prop="floor">
            <span>{{formData.floor}}</span>
          </a-form-model-item>
          <a-form-model-item label="作业内容" class="nobg" prop="content">
            <a-textarea style="margin-left: -7px" v-model="formData.content" disabled/>
          </a-form-model-item>
          <a-form-model-item label="打点位置" prop="work_address">
            <span>{{formData.work_address}}</span>
          </a-form-model-item>
          <a-form-model-item label="关联摄像机名称/编号" prop="monitor_num">
            <span>{{formData.monitor_num}}</span>
          </a-form-model-item>
          <a-form-model-item v-if="formData.work_child_type==='垃圾处理'" label="垃圾分类" prop="garbage_type">
            <span>{{formData.garbage_type}}</span>
          </a-form-model-item>
          <a-form-model-item v-if="formData.work_child_type==='垃圾处理'" label="清运类型" prop="clear_type">
            <span>{{formData.clear_type}}</span>
          </a-form-model-item>
          <a-form-model-item v-if="formData.work_child_type==='垃圾处理'" label="单位" prop="unit">
            <span>{{formData.unit}}</span>
          </a-form-model-item>
          <a-form-model-item v-if="formData.work_child_type==='垃圾处理'" label="数量" prop="num">
            <span>{{formData.num}}</span>
          </a-form-model-item>
          <a-form-model-item label="备注" class="nobg" prop="remark">
            <a-textarea style="margin-left: -7px" v-model="formData.remark" disabled/>
          </a-form-model-item>
          <h3 style="font-weight: bold; color: #333;">作业图片/视频</h3>
          <div class="picture-list">
            <div class="picture-line" v-for="(item, index) in cameraList" :key="index" style="margin-left: 15%">
              <h3>{{item.picTypeName}}：<span v-if="item.time_anomaly==='1'" style="color: red;font-size: small"><span style="font-size: large">时间异常</span> （图片拍摄时间和作业时间相差超过24小时）</span></h3>
              <div v-if="item.pic_type == '16' || item.pic_type == '22'" style="overflow: hidden;padding-bottom: 8px;">
                <video v-for="(cItem, cIndex) in item.list" :key="cIndex" :src="cItem.url" controls="controls"></video>
              </div>
              <a-upload v-else list-type="picture-card" :file-list="item.list" disabled></a-upload>
            </div>
          </div>
<!--        </a-tab-pane>-->
<!--      </a-tabs>-->
    </a-form-model>
  </a-modal>
</template>
<script>
import moment from 'moment'
import {getCache} from "U";
import {getWorkOrderInfoByOrderId, getWorkOrderListByCondition, getWorkOrderPicByOrderId} from "A/businessmanagement";
import {workOrderPicType} from "@/json/businessmanagement";
export default {
  props: {
    visible: {
      default: false
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalVisible: false,
      modalBodyStyle: {
        height: '560px',
        overflowY: 'auto',
      },
      workOrderPicType,
      formData: {
        monitorpointnum:'',
        work_type:'',
        work_child_type:'',
        work_person:'',
        work_address:'',
        monitor_num:'',
        work_time:'',
        work_status:'',
        point_name:'',
        garbage_type:'',
        clear_type:'',
        unit:'',
        num:'',
        region:'',
        floor:'',
        content:'',
        plan_id:'',
        task_id:'',
        unit_name:'',
        order_exception:'',
        remark:'',
      },
      tableData: [],
      cameraList: [],
    }
  },
  computed: {
    modalTitle() {
      return '详情'
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail();
        this.getPictureList();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetData();
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
    }
  },
  methods: {
    initDetail() {
      if(this.detailData && this.detailData.order_id) {
        this.$nextTick(() => {
          let params = {
            order_id: this.detailData.order_id
          }
          getWorkOrderListByCondition(params).then(res => {
            if(res && res.returncode == '0') {
              for(let key in this.formData) {
                this.formData[key] = res.item[0][key]
              }
            }
          })
        })
      }
    },
    resetData() {
      this.formData.order_id = '';
      this.tableData = [];
      this.cameraList = [];
      this.$refs.modalForm.resetFields();
    },
    getPictureList() {
      let params = {
        order_id: this.detailData.order_id
      };
      getWorkOrderPicByOrderId(params).then(res => {
        if(res && res.returncode == '0') {
          let cameraListMap = {};
          res.item.forEach((item, index) => {
            if(!cameraListMap[item.pic_type]) {
              cameraListMap[item.pic_type] = [];
            }
            let obj = {
              ...item,
              uid: 'uid-'+item.pic_type+'-'+index,
              status: 'done',
              url: item.pic_url,
            };
            let nameIndex = item.pic_url.lastIndexOf('/');
            if(nameIndex < 0) {
              nameIndex = item.pic_url.lastIndexOf('\\');
            }
            if(nameIndex > -1) {
              obj.name = item.pic_url.substr(nameIndex+1);
            }
            obj.picTypeName = workOrderPicType[item.pic_type] || '';
            cameraListMap[item.pic_type].push(obj);
          })
          for(let key in cameraListMap) {
            this.cameraList.push({
              pic_type: cameraListMap[key][0].pic_type,
              picTypeName: cameraListMap[key][0].picTypeName,
              list: cameraListMap[key],
              time_anomaly:cameraListMap[key][0].time_anomaly
            })
          }
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.picture-list {
  padding-top: 5px;
  .picture-line {
    margin-top: 10px;
    // border-top: solid 1px #e8e8e8;
  }
  video {
    float: left;
    width: 104px;
    margin-right: 8px;
  }
}
</style>