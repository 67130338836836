<template>
  <page-container :title="pageTitle">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner" style="margin: -35px -30px 10px -30px">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointnum" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="查询时段" prop="queryInstallDate" style="width: 25%">
              <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
            </a-form-model-item>
            <a-form-model-item label="类型" prop="eventtype" style="width: 25%">
              <a-select v-model="queryParams.eventtype">
                <a-select-option value="">全部</a-select-option>
                <a-select-option :value="902">异常</a-select-option>
                <a-select-option :value="901">报警</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" style="margin-right: 10px" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="情况描述" prop="description" style="width: 25%">
                <a-input v-model="queryParams.description"></a-input>
              </a-form-model-item>
              <a-form-model-item label="姓名" prop="username" style="width: 25%">
                <a-input v-model="queryParams.username"></a-input>
              </a-form-model-item>
              <a-form-model-item label="账号" prop="useraccount" style="width: 25%">
                <a-input v-model="queryParams.useraccount"></a-input>
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner" style="margin: -25px -30px 10px -30px">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-button @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="eventstarttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="eventtype" slot-scope="value">{{value===901?"报警":"异常"}}</span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <event-record-detail  :visible.sync="modalVisible" :detail-data="modalDetailData" @get-operation-result="getTableData"></event-record-detail>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapState} from "vuex";
import pagination from "@/mixins/pagination";
import deptselect from "@/mixins/deptselect";
import {exportYardPersonEventRecordExcel, getYardPersonEventRecordListByCondition} from "A/employeeStatus";
import {getTimeRange} from "U";
import EventRecordDetail from "./EventRecordDetail.vue"
import DetailModal from "V/workCheck/employeeStatus/order/maintenancePerson/DetailModal.vue";
export default {
  name: "yardPerson",
  mixins: [deptselect,pagination],
  components: {
    DetailModal,
    EventRecordDetail,
  },
  data() {
    return {
      moment,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      queryInstallDate:null,
      queryParams:{
        monitorpointnum:'',
        monitorpointname:'',
        eventtype:'',
        username:'',
        useraccount:'',
        description:'',
        start_time:'',
        end_time:'',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '姓名',
          dataIndex: 'username',
          key: 'username',
          ellipsis: true,
        },
        {
          title: '账号',
          dataIndex: 'useraccount',
          key: 'useraccount',
          ellipsis: true,
        },
        {
          title: '记录时间',
          dataIndex: 'eventstarttime',
          key: 'eventstarttime',
          ellipsis: true,
          scopedSlots: { customRender: 'eventstarttime' },
        },
        {
          title: '类型',
          dataIndex: 'eventtype',
          key: 'eventtype',
          ellipsis: true,
          scopedSlots: { customRender: 'eventtype' },
        },
        {
          title: '情况描述',
          dataIndex: 'description',
          key: 'description',
          ellipsis: true,
        },
      ],
      userdepidCascaderSelected: [],
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "调度员"
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
  },
  created() {
    let time = new Date();
    let monthNum = moment(time).format("YYYY-MM").slice(5);
    this.queryParams.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    this.getTableData()
    this.initDeptOptionsAll();
  },
  methods:{
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getYardPersonEventRecordListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.queryInstallDate = [];
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportEmployeeStatus()
      }).catch(()=>{
      });
    },
    exportEmployeeStatus(){
      let params = {
        ...this.queryParams,
      }
      this.showLoading();
      exportYardPersonEventRecordExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.modalDetailData=record
            this.modalShowType="detail"
            this.modalVisible=true
          },
        },
      }
    },
    showModal(type,record) {
      this.modalDetailData = record
      this.modalShowType = type
      this.modalVisible = true
    },
  },
}
</script>
<style scoped>

</style>