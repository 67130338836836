<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <a-tabs>
          <a-tab-pane key="1" tab="维保人员">
            <maintenance-person></maintenance-person>
          </a-tab-pane>
          <a-tab-pane key="2" tab="调度员">
            <yard-person></yard-person>
          </a-tab-pane>
          <a-tab-pane key="3" tab="调度员巡查完成情况">
            <yard-patrol></yard-patrol>
          </a-tab-pane>
          <a-tab-pane key="4" tab="人工巡查">
            <manual-patrol></manual-patrol>
          </a-tab-pane>
          <a-tab-pane key="5" tab="环境作业">
            <environment-person></environment-person>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </page-container>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import MaintenancePerson from "./maintenancePerson/Index.vue"
import YardPerson from "./yardPerson/Index.vue"
import YardPatrol from "./yardPatrol/Index.vue"
import ManualPatrol from "./manualPatrol/Index.vue"
import environmentPerson from "./environmentPerson/Index.vue"
export default {
  name: "employeeStatus",
  components:{
    MaintenancePerson,
    YardPerson,
    YardPatrol,
    ManualPatrol,
    environmentPerson,
  },
  data(){
    return{
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    pageTitle() {
      return "工作情况分析"
    },
    breadcrumb() {
      const pages = [{name:"工作查阅与报告",path:""}]
      pages.push({name:"员工工作状态分析",path:""})
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  methods: {
  },
}
</script>
<style scoped>

</style>