export const maintenanceTypes = [
  {value: 1, name: '半月保养'},
  {value: 2, name: '季度保养'},
  {value: 3, name: '半年保养'},
  {value: 4, name: '年度保养'},
  {value: 51, name: '按需保养-3个月去一次'},
  {value: 52, name: '按需保养-2个月去一次'},
  {value: 53, name: '按需保养-1个月去一次'},
]

export const maintenancePicType = {
  '10': '保养签到',
  '11': '保养前照片',
  '12': '保养后照片',
  '16': '保养视频',
  '17': '保养签名',
  '20': '维修签到',
  '21': '维修现场',
  '22': '维修视频',
  '23': '维修签名',
}

export const maintenanceItemStatus = {
  '1': '正常',
  '2': '保养后正常',
  '3': '已报修',
  '4': '无',
  '5': '数值',
}

export const inspectionPicType = {
  '11': '巡查现场图片',
  '16': '巡查现场视频',
  '17': '巡查签名',
}

export const orderException = {
  "0": "正常",
  "1": "异常",
  "2": "异常",
}
export const orderExceptionColors = {
  "0": "green",
  "1": "red",
  "2": "red",
}