<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="1000" :centered="true" :body-style="modalBodyStyle">
    <template slot="footer">
      <a-button type="primary" @click="modalVisible=false">关闭</a-button>
    </template>
    <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns detail-form-bg" :model="formData" :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-tabs type="card">
        <a-tab-pane key="1" tab="保养信息">
          <h3 style="font-weight: bold; color: #333;">保养信息</h3>
          <a-form-model-item label="保养单号" prop="order_num">
            <span>{{formData.order_num}}</span>
          </a-form-model-item>
          <a-form-model-item label="设备类型" prop="equipment_type">
            <span>{{formData.equipment_type.length==2?formData.equipment_type.substring(0,1)==0?deviceCodeList[formData.equipment_type.replace('0','')-1].dicvalue:deviceCodeList[formData.equipment_type-1].dicvalue:''}}</span>
          </a-form-model-item>
          <a-form-model-item label="设备编号" prop="equipment_num">
            <span>{{formData.equipment_num}}</span>
          </a-form-model-item>
          <a-form-model-item label="设备名称" prop="equipment_name">
            <span>{{formData.equipment_name}}</span>
          </a-form-model-item>
          <a-form-model-item label="安装位置" prop="address">
            <span>{{formData.address}}</span>
          </a-form-model-item>
          <a-form-model-item label="产生时间" prop="create_time">
            <span>{{create_timeShow}}</span>
          </a-form-model-item>
          <a-form-model-item label="计划完成日期" prop="need_finish_time">
            <span>{{need_finish_timeShow}}</span>
          </a-form-model-item>
<!--          <a-form-model-item label="保养类型" prop="maintenance_schedule_type">-->
<!--            <span>{{}}</span>-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="保养周期" prop="maintenance_period">
            <span>{{formData.maintenance_period?maintenancePeriodList[formData.maintenance_period].dicvalue:''}}</span>
          </a-form-model-item>
          <a-form-model-item label="保养单位" prop="maintenance_unit_name">
            <span>{{formData.maintenance_unit_name}}</span>
          </a-form-model-item>
          <a-form-model-item label="维保组名称" prop="maintenance_group_name">
            <span>{{formData.maintenance_group_name}}</span>
          </a-form-model-item>
          <a-form-model-item label="保养类型" prop="task_type">
            <span>{{taskType[formData.task_type]}}</span>
          </a-form-model-item>
          <a-form-model-item label="状态" prop="order_status">
            <span>{{orderStatus[formData.order_status]||''}}</span>
          </a-form-model-item>
          <a-form-model-item label="流程节点" prop="process_node">
            <span>{{orderSteps[formData.process_node]||''}}</span>
          </a-form-model-item>
          <a-form-model-item label="关联摄像机名称/编号" prop="monitor_num">
            <span>{{formData.monitor_num}}</span>
          </a-form-model-item>
          <h3 style="font-weight: bold; color: #333;">派单信息</h3>
          <a-form-model-item label="派单人员" prop="dispatch_person">
            <span>{{formData.dispatch_person}}</span>
          </a-form-model-item>
          <a-form-model-item label="派单时间" prop="dispatch_time">
            <span>{{formData.dispatch_time ? moment(formData.dispatch_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-form-model-item>
          <a-form-model-item label="接单人员" prop="take_person">
            <span>{{formData.take_person}}</span>
          </a-form-model-item>
          <a-form-model-item label="接单时间" prop="take_time">
            <span>{{formData.take_time ? moment(formData.take_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-form-model-item>
          <a-form-model-item label="签到人员" prop="register_person">
            <span>{{formData.register_person}}</span>
          </a-form-model-item>
          <a-form-model-item label="签到时间" prop="register_time">
            <span>{{formData.register_time ? moment(formData.register_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-form-model-item>
          <a-form-model-item label="签到地点" prop="sign_in_address">
            <span>{{formData.sign_in_address}}</span>
          </a-form-model-item>
<!--          <a-form-model-item label="扫码信息" prop="scan_info">-->
<!--            <span>{{formData.scan_info}}</span>-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="保养开始时间" prop="check_start_time">
            <span>{{formData.check_start_time ? moment(formData.check_start_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-form-model-item>
          <a-form-model-item label="保养结束时间" prop="check_end_time">
            <span>{{formData.check_end_time ? moment(formData.check_end_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-form-model-item>
          <a-form-model-item label="保养时长" prop="check_time">
            <span>{{formData.check_time}}</span><span v-if="formData.order_exception==='1'&&(timeDifference()>86400||timeDifference()<180)" style="color: red">（时间异常）</span>
          </a-form-model-item>
          <a-form-model-item label="保养结论" prop="result_code">
            <span>{{resultCode[formData.result_code]}}</span>
          </a-form-model-item>
<!--          <a-form-model-item v-if="formData.order_exception!=='0'" label="保养异常" prop="order_exception">-->
<!--            <span style="color: red">{{formData.order_exception==='1'?"保养图片拍摄时间和保养时间相差超过24小时":formData.order_exception==='2'?"保养图片拍摄时间不在保养开始和结束时间区间内":""}}</span>-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="保养说明" prop="order_decs">
            <span>{{formData.order_decs}}</span>
          </a-form-model-item>
          <a-form-model-item label="维修内容" prop="maintenance_content">
            <span>{{formData.maintenance_content}}</span>
          </a-form-model-item>
          <a-form-model-item label="更换零部件" prop="replace_parts">
            <span>{{formData.replace_parts}}</span>
          </a-form-model-item>
          <a-form-model-item label="维修费用" prop="maintenance_costs">
            <span>{{formData.maintenance_costs}}</span>
          </a-form-model-item>
          <a-form-model-item label="维修原因" prop="fault">
            <span>{{formData.fault}}</span>
          </a-form-model-item>
          <h3 style="font-weight: bold; color: #333;">评价信息</h3>
          <a-form-model-item label="组长评价" class="nobg" prop="group_man_audit_grade">
            <a-radio-group v-model="formData.group_man_audit_grade" disabled style="width: 400px">
              <a-radio :value="1">不合格</a-radio>
              <a-radio :value="2">合格</a-radio>
              <a-radio :value="3">良好</a-radio>
              <a-radio :value="4">优秀</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="评价人" prop="group_man">
            <span>{{formData.group_man}}</span>
          </a-form-model-item>
          <a-form-model-item label="评价内容" class="nobg" prop="group_man_audit_evaluation">
            <a-textarea v-model="formData.group_man_audit_evaluation" disabled/>
          </a-form-model-item>
          <div></div>
          <a-form-model-item label="物业评价" class="nobg" prop="property_audit_grade">
            <a-radio-group v-model="formData.property_audit_grade" disabled style="width: 400px">
              <a-radio value="1">不合格</a-radio>
              <a-radio value="2">合格</a-radio>
              <a-radio value="3">良好</a-radio>
              <a-radio value="4">优秀</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="评价人" prop="property_man">
            <span>{{formData.property_man}}</span>
          </a-form-model-item>
          <a-form-model-item label="评价内容" class="nobg" prop="property_audit_evaluation">
            <a-textarea v-model="formData.property_audit_evaluation" disabled/>
          </a-form-model-item>
        </a-tab-pane>
        <a-tab-pane key="2" tab="保养项目">
          <a-table :columns="tableColumns" :data-source="tableData" row-key="item_record_id" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" :pagination="false">
            <span slot="index" slot-scope="value, record, index">
              {{index+1}}
            </span>
            <span slot="item_status" slot-scope="value">
              {{maintenanceItemStatus[value]}}
            </span>
          </a-table>
          <div class="picture-list">
            <div class="picture-line" v-for="(item, index) in cameraList" :key="index">
              <h3>{{item.picTypeName}}：
                <span v-if="item.time_anomaly==='1'" style="color: red;font-size: small"><span style="font-size: large">时间异常</span> （图片拍摄时间和保养时间相差超过24小时）</span>
                <span v-if="item.time_anomaly==='2'" style="color: red;font-size: small"><span style="font-size: large">时间异常</span> （图片拍摄时间不在保养开始和结束时间区间内）</span>
              </h3>
              <div v-if="item.pic_type == '16' || item.pic_type == '22'" style="overflow: hidden;padding-bottom: 8px;">
                <video v-for="(cItem, cIndex) in item.list" :key="cIndex" :src="cItem.url" controls="controls"></video>
              </div>
              <a-upload v-else list-type="picture-card" :file-list="item.list" disabled></a-upload>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="3" tab="保养追踪">
          <a-timeline>
            <a-timeline-item v-for="(item, index) in workFlow" :key="index" :color="index<workFlowIndex?'green':'gray'">
              <div>操作步骤: {{orderSteps[item.status]}}</div>
              <div>操作人员: {{item.username?item.username+"（"+item.person+"）":item.person}}</div>
              <div>完成时间: {{item.finish_time?moment(item.finish_time,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):""}}</div>
            </a-timeline-item>
          </a-timeline>
        </a-tab-pane>
      </a-tabs>
    </a-form-model>
  </a-modal>
</template>
<script>
import moment from 'moment'
import { getWorkflowByOrderId, getMaintenanceItemRecordListByOrderId, getMaintenancePicByOrderId, getMaintenanceOrderInfoByOrderId } from 'A/maintenance.js'
import {orderStatus, orderSteps, resultCode} from "@/json/orderSteps";
import {maintenanceItemStatus, maintenancePicType} from "@/json/maintenance";
import {getCache} from "U";
export default {
  props: {
    visible: {
      default: false
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      orderSteps,
      maintenanceItemStatus,
      maintenancePicType,
      orderStatus,
      resultCode,
      deviceCodeList: [],
      maintenancePeriodList: [],
      taskType: ["外委","自保"],
      modalVisible: false,
      modalBodyStyle: {
        height: '560px',
        overflowY: 'auto',
      },
      formData: {
        maintenance_order_id: '',
        equipment_type: '',
        equipment_num: '',
        equipment_name: '',
        order_num: '',
        address: '',
        create_time: '',
        need_finish_time: '',
        maintenance_schedule_type: '',
        maintenance_period: '',
        maintenance_unit_name: '',
        order_status: '',
        process_node: '',
        user_audit_grade: 0,
        user_audit_evaluation:'',
        group_man: '',
        group_man_audit_grade: 0,
        group_man_audit_evaluation:'',
        property_man: '',
        property_audit_grade: 0,
        property_audit_evaluation:'',
        order_exception:'',

        dispatch_person: '',
        dispatch_time: '',
        take_person: '',
        take_time: '',
        register_person: '',
        register_time: '',
        sign_in_address: '',
        scan_info:'',
        check_start_time: '',
        check_end_time: '',
        check_time:'',
        finish_time:'',
        result_code: '',
        fault: '',
        order_decs: '',
        result_decs:'',

        maintenance_content:'',
        replace_parts:'',
        maintenance_costs:'',
        task_type:'',
        maintenance_group_name:'',

        monitor_num:'',
      },
      tableColumns: [
        {
          title: '序号',
          key: 'index',
          width: 100,
          align: 'center',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '项目名称',
          dataIndex: 'item_name',
          key: 'item_name',
          ellipsis: true,
        },
        {
          title: '状态',
          dataIndex: 'item_status',
          key: 'item_status',
          width: 100,
          scopedSlots: { customRender: 'item_status' }
        },
        {
          title: '检查结果',
          dataIndex: 'result_desc',
          key: 'result_desc',
          ellipsis: true,
        },
      ],
      tableData: [],
      cameraList: [],
      workFlow: [],
      workFlowIndex: 0,
    }
  },
  computed: {
    modalTitle() {
      return '详情'
    },
    create_timeShow() {
      if(this.formData.create_time) {
        return moment(this.formData.create_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')
      }else {
        return '';
      }
    },
    need_finish_timeShow() {
      if(this.formData.need_finish_time) {
        return moment(this.formData.need_finish_time, 'YYYYMMDD').format('YYYY-MM-DD')
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail();
        this.getTableData();
        this.getPictureList();
        this.getWorkFlow();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetData();
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.deviceCodeList = dictionary.deviceCodeList
      this.maintenancePeriodList = dictionary.maintenancePeriodList
    }
  },
  methods: {
    timeDifference(){
      return moment(moment(this.formData.check_end_time,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")).diff(moment(this.formData.register_time,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"),'seconds',false)
    },
    initDetail() {
      if(this.detailData && this.detailData.maintenance_order_id) {
        this.$nextTick(() => {
          let params = {
            maintenance_order_id: this.detailData.maintenance_order_id
          }
          getMaintenanceOrderInfoByOrderId(params).then(res => {
            if(res && res.returncode == '0') {
              for(let key in this.formData) {
                if('user_audit_grade'==key||'group_man_audit_grade'==key) {
                  this.formData[key] = Number(res.item[key]||0)
                }else {
                  this.formData[key] = res.item[key]
                }
              }
              this.getWorkFlowIndex();
            }
          })
        })
      }
    },
    resetData() {
      this.formData.maintenance_order_id = '';
      this.tableData = [];
      this.cameraList = [];
      this.$refs.modalForm.resetFields();
    },
    getWorkFlow() {
      let params = {
        maintenance_order_id: this.detailData.maintenance_order_id
      };
      getWorkflowByOrderId(params).then(res => {
        if(res && res.returncode == '0') {
          this.workFlow = res.item;
          this.getWorkFlowIndex();
        }
      })
    },
    getWorkFlowIndex() {
      if(this.formData.process_node && this.workFlow && this.workFlow.length) {
        this.workFlow.forEach((item, index) => {
          if(item.status == this.formData.process_node) {
            this.workFlowIndex = index;
          }
        })
      }
    },
    getTableData() {
      let params = {
        maintenance_order_id: this.detailData.maintenance_order_id
      };
      getMaintenanceItemRecordListByOrderId(params).then(res => {
        if(res && res.returncode == '0') {
          this.tableData = res.item;
        }
      })
    },
    getPictureList() {
      let params = {
        maintenance_order_id: this.detailData.maintenance_order_id
      };
      getMaintenancePicByOrderId(params).then(res => {
        if(res && res.returncode == '0') {
          let cameraListMap = {};
          res.item.forEach((item, index) => {
            if(!cameraListMap[item.pic_type]) {
              cameraListMap[item.pic_type] = [];
            }
            let obj = {
              ...item,
              uid: 'uid-'+item.pic_type+'-'+index,
              status: 'done',
              url: item.pic_url,
            };
            let nameIndex = item.pic_url.lastIndexOf('/');
            if(nameIndex < 0) {
              nameIndex = item.pic_url.lastIndexOf('\\');
            }
            if(nameIndex > -1) {
              obj.name = item.pic_url.substr(nameIndex+1);
            }
            obj.picTypeName = maintenancePicType[item.pic_type] || '';
            cameraListMap[item.pic_type].push(obj);
          })
          for(let key in cameraListMap) {
            this.cameraList.push({
              pic_type: cameraListMap[key][0].pic_type,
              picTypeName: cameraListMap[key][0].picTypeName,
              list: cameraListMap[key],
              time_anomaly:cameraListMap[key][0].time_anomaly
            })
          }
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.picture-list {
  padding-top: 5px;
  .picture-line {
    margin-top: 10px;
    // border-top: solid 1px #e8e8e8;
  }
  video {
    float: left;
    width: 104px;
    margin-right: 8px;
  }
}
</style>