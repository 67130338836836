export const repairOrderSteps = {
  '10': '待处理',
  '20': '待调度员派单',
  '30': '待签到',
  '40': '待执行',
  '50': '申请延期中',
  '60': '申请强关中',
  '70': '待结单',
  '80': '待验收',
  '90': '待评价',
  '11': '派单完成',
  '21': '调度员接单完成',
  '31': '接单完成',
  '41': '签到完成',
  '51': '工单延期',
  '61': '工单强关',
  '71': '结单完成',
  '81': '验收完成',
  '91': '已完成',
}

export const repairOrderStepColor = {
  '10': 'red',
  '20': 'pink',
  '30': 'pink',
  '40': 'pink',
  '50': 'red',
  '60': 'red',
  '70': 'orange',
  '80': 'blue',
  '90': 'blue',
  '11': 'pink',
  '21': 'pink',
  '31': 'pink',
  '41': 'pink',
  '51': 'orange',
  '61': 'orange',
  '71': 'green',
  '81': 'green',
  '91': 'green',
}

export const repairOrderStepList = [
  { value: '10', name: '待处理'},
  { value: '20', name: '待调度员派单'},
  { value: '30', name: '待签到'},
  { value: '40', name: '待执行'},
  { value: '50', name: '申请延期中'},
  { value: '60', name: '申请强关中'},
  { value: '70', name: '待结单'},
  { value: '80', name: '待验收'},
  { value: '90', name: '待评价'},
  { value: '91', name: '已完成'},
]

export const repairOrderStatus = {
  '1': '正常',
  '2': '异常',
  '3': '撤销',
}

export const orderStatus = {
  '1': '正常',
  '2': '异常',
  '3': '拆分',
  '4': '延期',
}

export const resultCode = {
  '1': '正常',
  '2': '保养后正常',
  '3': '报修（带病运行）',
  '4': '报修（停止运行）',
}

export const mattersPicType = {
  '10': '现场签到',
  '11': '处理前照片',
  '12': '处理后照片',
  '16': '处理视频',
  '17': '处理人签名',
  '24': '验收签名',
}

export const repairsPicType = {
  '10': '现场签到',
  '11': '维修前照片',
  '12': '维修后照片',
  '16': '维修视频',
  '17': '维修人签名',
  '24': '验收签名',
}