/*
 * @Author: feng
 * @Date: 2020-11-03 21:54:41
 * @Description: 引用该mixin还需要做以下操作
 * @Description: 1. watch监听 deviceCascaderSelected ,处理form表单的devicecode
 * @Description: 2. 数据反显调用this.initDeviceOptionsByDevicecode(devicecode)方法
 * @Description: 3. 清空表单时重置this.deviceCascaderSelected = []
 * @Description: 4. 模板使用下面代码
 * <a-cascader :field-names="{ label: 'devicename', value: 'devicecode', children: 'children' }" :options="deviceOptions" v-model="deviceCascaderSelected" :load-data="deviceOnLoadChildren" placeholder="请选择区域" :allow-clear="false"></a-cascader>
 */
import { getItemFromArrayByKey, getTreeDeepString } from 'U'
import { getChildDeviceListByParentCode, getParentDeviceTreeByCode } from 'A/xtpz.js'

export default {
  data() {
    return {
      deviceOptions: [],
      deviceCascaderSelected: [],
    }
  },
  created() {
    this.initDeviceOptions();
  },
  methods: {
    initDeviceOptions(parentcode='') {
      let params = {
        parentcode
      };
      getChildDeviceListByParentCode(params).then(res => {
        if(res && res.returncode == '0') {
          this.deviceOptions = res.item.map(item => {
            item.isLeaf = (item.devicecode.length == 6 && item.devicecode.substr(-2) != '00');
            return item;
          });
        }
      })
    },
    deviceOnLoadChildren(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      this.getChildDevice(targetOption.devicecode);
    },
    getChildDevice(parentcode) {
      let targetOption = getItemFromArrayByKey(this.deviceOptions, 'devicecode', parentcode);
      let params = {
        parentcode
      }
      getChildDeviceListByParentCode(params).then(res => {
        targetOption.loading = false;
        if(res && res.returncode == '0') {
          targetOption.children = res.item.map(item => {
            item.isLeaf = (item.devicecode.length == 6 && item.devicecode.substr(-2) != '00');
            return item;
          });
          this.deviceOptions = [...this.deviceOptions]
        }
      }).catch(err => {
        targetOption.loading = false;
      })
    },
    initDeviceOptionsByDevicecode(devicecode) {
      let params = {
        devicecode
      }
      getParentDeviceTreeByCode(params).then(res => {
        if(res && res.returncode == '0') {
          let devicecodeTreeStr = getTreeDeepString(res.item, '', 'devicecode', 'childdevice');
          let deepDeviceList = devicecodeTreeStr.split('|');
          if(deepDeviceList.length > 1) {
            this.initDeviceListTree(deepDeviceList, 0);
          }else {
            this.deviceCascaderSelected = deepDeviceList;
          }
        }
      })
    },
    initDeviceListTree(deepDeviceList, index) {
      if(index < deepDeviceList.length-1) {
        let parentcode = deepDeviceList[index];
        let targetOption = getItemFromArrayByKey(this.deviceOptions, 'devicecode', parentcode);
        let params = {
          parentcode
        }
        getChildDeviceListByParentCode(params).then(res => {
          if(res && res.returncode == '0') {
            targetOption.children = res.item.map(item => {
              item.isLeaf = (item.devicecode.length == 6 && item.devicecode.substr(-2) != '00');
              return item;
            });
            // this.deviceOptions = [...this.deviceOptions]
            this.initDeviceListTree(deepDeviceList, index+1)
          }
        })
      }else {
        this.deviceCascaderSelected = deepDeviceList;
      }
    },
  }
}