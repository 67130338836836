<template>
  <page-container :title="pageTitle">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner" style="margin: -35px -30px 10px -30px">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointnum" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="查询时段" prop="queryInstallDate" style="width: 25%">
              <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
            </a-form-model-item>
            <a-form-model-item label="工作内容" prop="work_type" style="width: 25%">
              <a-select v-model="queryParams.work_type">
                <a-select-option value="保养">保养</a-select-option>
                <a-select-option value="维修">维修</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" style="margin-right: 10px" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="姓名" prop="person_name" style="width: 25%">
                <a-input v-model="queryParams.person_name"></a-input>
              </a-form-model-item>
              <a-form-model-item label="账号" prop="person" style="width: 25%">
                <a-input v-model="queryParams.person"></a-input>
              </a-form-model-item>
              <a-form-model-item v-if="queryParams.work_type==='保养'" label="维保角色" prop="rolename" style="width: 25%">
                <a-select v-model="queryParams.rolename">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="维保组长">维保组长</a-select-option>
                  <a-select-option value="维保组员">维保组员</a-select-option>
                  <a-select-option value="自保组长">自保组长</a-select-option>
                  <a-select-option value="自保组员">自保组员</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item v-if="queryParams.work_type==='保养'" label="设备类型" prop="equipment_type" style="width: 25%">
                <a-select v-model="queryParams.equipment_type">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option v-for="(item, index) in deviceCodeList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item v-if="queryParams.work_type==='保养'" label="设备编号" prop="equipment_num" style="width: 25%">
                <a-input v-model="queryParams.equipment_num"></a-input>
              </a-form-model-item>
              <a-form-model-item label="作业时间状态" prop="order_exception" style="width: 25%">
                <a-select v-model="queryParams.order_exception">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="0">正常</a-select-option>
                  <a-select-option value="1">异常</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="物业评价" prop="property_audit_grade" style="width: 25%">
                <a-select v-model="queryParams.property_audit_grade">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="1">不合格</a-select-option>
                  <a-select-option value="2">合格</a-select-option>
                  <a-select-option value="3">良好</a-select-option>
                  <a-select-option value="4">优秀</a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner" style="margin: -25px -30px 10px -30px">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-button @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="register_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="finish_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="equipment_type" slot-scope="value,record">{{value?value.length==2?value.substring(0,1)==0?deviceCodeList[value.replace('0','')-1].dicvalue:deviceCodeList[value-1].dicvalue:'':''}}</span>
          <span slot="order_exception" slot-scope="value">
            <a-tag :color="orderExceptionColors[value]||''">{{orderException[value]||''}}</a-tag>
          </span>
          <span slot="duration" slot-scope="value">{{timeHandle(value)}}</span>
          <span slot="content" slot-scope="value,record">
            <span :title="queryParams.work_type==='保养'&&record.hasOwnProperty('maintenance_order_id')?value?maintenancePeriodList[value].dicvalue:'':value">{{queryParams.work_type==='保养'&&record.hasOwnProperty('maintenance_order_id')?value?maintenancePeriodList[value].dicvalue:'':value}}</span>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <detail-modal :visible.sync="modalVisible" :detail-data="modalDetailData" @get-operation-result="getTableData"></detail-modal>
    <repair-detail-modal :visible.sync="repairModalVisible" :detail-data="repairModalDetailData" @get-operation-result="getTableData"></repair-detail-modal>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapState} from "vuex";
import pagination from "@/mixins/pagination";
import deptselect from "@/mixins/deptselect";
import {exportMaintenancePersonExcel, getMaintenancePersonListByCondition} from "A/employeeStatus";
import {orderException, orderExceptionColors} from "@/json/maintenance";
import {getCache, getTimeRange} from "U";
import DetailModal from "./DetailModal.vue";
import RepairDetailModal from "./RepairDetailModal.vue";
export default {
  name: "maintenancePerson",
  mixins: [deptselect,pagination],
  components: {
    RepairDetailModal,
    DetailModal,
  },
  data() {
    return {
      moment,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      orderException,
      orderExceptionColors,
      deviceCodeList:[],
      maintenancePeriodList:[],
      gradeList :["不合格","合格","良好","优秀"],
      queryInstallDate:null,
      queryParams:{
        monitorpointnum:'',
        monitorpointname:'',
        person:'',
        person_name:'',
        work_type:'保养',
        property_audit_grade:'',
        rolename:'',
        equipment_type:'',
        equipment_num:'',
        order_exception:'',
        start_time:'',
        end_time:'',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '姓名',
          dataIndex: 'person_name',
          key: 'person_name',
          ellipsis: true,
          width: 70,
        },
        {
          title: '账号',
          dataIndex: 'person',
          key: 'person',
          ellipsis: true,
          width: 100,
        },
        {
          title: '签到时间',
          dataIndex: 'register_time',
          key: 'register_time',
          ellipsis: true,
          width: 140,
          scopedSlots: { customRender: 'register_time' },
        },
        {
          title: '完成时间',
          dataIndex: 'finish_time',
          key: 'finish_time',
          ellipsis: true,
          width: 140,
          scopedSlots: { customRender: 'finish_time' },
        },
        {
          title: '工作时长',
          dataIndex: 'duration',
          key: 'duration',
          ellipsis: true,
          width: 100,
          scopedSlots: { customRender: 'duration' },
        },
        {
          title: '设备编号',
          dataIndex: 'equipment_num',
          key: 'equipment_num',
          ellipsis: true,
        },
        {
          title: '设备类型',
          dataIndex: 'equipment_type',
          key: 'equipment_type',
          ellipsis: true,
          scopedSlots: { customRender: 'equipment_type' },
        },
        {
          title: '设备名称',
          dataIndex: 'equipment_name',
          key: 'equipment_name',
          ellipsis: true,
        },
        {
          title: '作业内容',
          dataIndex: 'content',
          key: 'content',
          ellipsis: true,
          scopedSlots: { customRender: 'content' },
        },
        {
          title: '作业时间状态',
          dataIndex: 'order_exception',
          key: 'order_exception',
          ellipsis: true,
          width: 100,
          scopedSlots: { customRender: 'order_exception' },
        },
        {
          title: '物业评价',
          dataIndex: 'property_audit_evaluation',
          key: 'property_audit_evaluation',
          ellipsis: true,
          width: 70,
        },
      ],
      userdepidCascaderSelected: [],
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      repairModalVisible: false,
      repairModalShowType: '',
      repairModalDetailData: null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "维保人员"
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
    "queryParams.work_type":function (val){
      if(val==="维修"){
        this.queryParams.equipment_num=""
      }
      this.queryParams.userdepsid=this.userInfo.useraccount==='admin'?null:this.userInfo.userdepid
    }
  },
  created() {
    this.queryParams.userdepsid=this.userInfo.useraccount==='admin'?null:this.userInfo.userdepid
    let time = new Date();
    let monthNum = moment(time).format("YYYY-MM").slice(5);
    this.queryParams.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    this.getTableData()
    this.initDeptOptionsAll();
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.deviceCodeList = dictionary.deviceCodeList
      this.maintenancePeriodList = dictionary.maintenancePeriodList
    }
  },
  methods:{
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      if(this.queryParams.work_type==="维修"&&this.queryParams.property_audit_grade){
        this.queryParams.property_audit_grade=this.gradeList[this.queryParams.property_audit_grade-1]
      }
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getMaintenancePersonListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.queryInstallDate = [];
      this.userdepidCascaderSelected=[]
      let work_type = this.queryParams.work_type
      this.$refs.queryForm.resetFields();
      this.queryParams.work_type=work_type
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportEmployeeStatus()
      }).catch(()=>{
      });
    },
    exportEmployeeStatus(){
      let params = {
        ...this.queryParams,
      }
      this.showLoading();
      exportMaintenancePersonExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            if(!record.hasOwnProperty("maintenance_order_id")){
              this.repairModalDetailData=record
              this.repairModalShowType="detail"
              this.repairModalVisible=true
            }else{
              this.modalDetailData=record
              this.modalShowType="detail"
              this.modalVisible=true
            }
          },
        },
      }
    },
    showModal(type,record) {
      if(!record.hasOwnProperty("maintenance_order_id")){
        this.repairModalDetailData=record
        this.repairModalShowType="detail"
        this.repairModalVisible=true
      }else{
        this.modalDetailData=record
        this.modalShowType="detail"
        this.modalVisible=true
      }
    },
    timeHandle(time){
      let returnTime;
      let day;
      let hour;
      let minutes;
      let seconds;
      if(time>86400) {
        day = Math.floor(time / 86400);
        returnTime=day + "天";
        if (time % 86400 > 3600) {
          hour = Math.floor(time % 86400 / 3600);
          returnTime+=hour + "时";
          if (time % 86400 % 3600 > 60) {
            minutes = Math.floor(time % 86400 % 3600 / 60);
            returnTime+=minutes + "分";
            seconds = Math.floor(time % 86400 % 3600 % 60);
            returnTime+=seconds + "秒";
          } else {
            seconds = Math.floor(time % 86400 % 3600 % 60);
            returnTime+=seconds + "秒";
          }
        } else {
          if (time % 86400 > 60) {
            minutes = Math.floor(time % 86400 / 60);
            returnTime+=minutes + "分";
            seconds = Math.floor(time % 86400 % 60);
            returnTime+=seconds + "秒";
          } else {
            seconds = Math.floor(time % 86400);
            returnTime+=seconds + "秒";
          }
        }
      }else if(time>=3600&&time<86400){
        let hour = Math.floor(time % 86400 / 3600);
        returnTime=hour + "时";
        if (time % 3600 > 60) {
          minutes = Math.floor(time % 3600 / 60);
          returnTime+=minutes + "分";
          seconds = Math.floor(time % 3600 % 60);
          returnTime+=seconds + "秒";
        } else {
          seconds = Math.floor(time % 3600 % 60);
          returnTime+=seconds + "秒";
        }
      }else if(time>=60&&time<3600){
        minutes = Math.floor(time / 60);
        returnTime=minutes + "分";
        seconds = Math.floor(time % 60);
        returnTime+=seconds + "秒";
      }else{
        returnTime=time + "秒";
      }
      return returnTime
    },
  },
}
</script>
<style scoped>

</style>