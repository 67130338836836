<template>
  <a-modal v-model="modalVisible" title="异常情况详情" :footer="false" :width="1200" :centered="true">
    <a-form-model ref="queryForm" :model="detailParams" layout="inline" class="query-form">
      <a-form-model-item label="事件编号" prop="eventnum" style="width: 30%">
        <a-input placeholder="系统自动生成" style="width: 200px;" v-model="detailParams.eventnum" :disabled="true"></a-input>
      </a-form-model-item>
      <a-form-model-item v-if="detailParams.eventtype!=902" label="系统名称(场景)" prop="systemnameid" style="width: 30%">
        <a-select default-value="" v-model="detailParams.systemnameid" placeholder="" style="width: 200px;" :disabled="true">
          <a-select-option v-for="(item, index) in systemNames" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="detailParams.eventtype!=902" label="报警时间" prop="eventstarttime" style="width: 30%">
        <a-date-picker show-time placeholder="" v-model="detailParams.eventstarttime" style="width: 200px;" :disabled="true"/>
      </a-form-model-item>
      <a-form-model-item v-if="detailParams.eventtype==902" label="事件类型" prop="eventcode" style="width: 30%">
        <a-select v-model="detailParams.eventcode" style="width: 200px;" :disabled="true">
          <a-select-option v-for="(item, index) in abnormalTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="detailParams.eventtype!=902" label="报警类型" prop="eventcode" style="width: 30%">
        <a-select v-model="detailParams.eventcode" placeholder="" style="width: 200px;" :disabled="true">
          <a-select-option v-for="(item, index) in alarmTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="detailParams.eventtype!=902&&(detailParams.systemnameid==7||detailParams.systemnameid==8)" label="报警子类型" prop="eventchildcode" style="width: 30%">
        <a-select v-model="detailParams.eventchildcode" placeholder="" style="width: 200px;" :disabled="true">
          <a-select-option v-for="(item, index) in alarmChildTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="重大影响" prop="biginfluence" style="width: 30%">
        <a-select v-model="detailParams.biginfluence" style="width: 200px;" :disabled="true">
          <a-select-option v-for="(item, index) in bigInfluences" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="detailParams.eventtype==902" label="管理类型" prop="managetype" style="width: 30%">
        <a-input v-model.trim="detailParams.managetype" placeholder="" style="width: 200px;" :disabled="true"></a-input>
      </a-form-model-item>
      <a-form-model-item v-if="detailParams.eventtype==902" label="发生时间" prop="eventstarttime" style="width: 30%">
        <a-date-picker show-time placeholder="" v-model="detailParams.eventstarttime" style="width: 200px;" :disabled="true"/>
      </a-form-model-item>
      <a-form-model-item v-if="detailParams.biginfluence!=1&&detailParams.plannedwork!=1" label="特别事件报告编号" prop="specialeventnum" style="width: 30%">
        <a-input v-model="detailParams.specialeventnum" placeholder="请输入" style="width: 200px;" :disabled="true"></a-input>
      </a-form-model-item>
      <a-form-model-item label="编号/位置" prop="location" style="width: 30%">
        <a-textarea v-model="detailParams.location" placeholder="" cols="24" rows="2" :disabled="true"></a-textarea>
      </a-form-model-item>
      <a-form-model-item v-if="detailParams.eventtype!=902" label="到场人员" prop="arriveman" style="width: 30%">
        <a-input v-model="detailParams.arriveman" placeholder="" style="width: 200px;" :disabled="true"></a-input>
      </a-form-model-item>
      <a-form-model-item v-if="detailParams.eventtype!=902" label="到场时间" prop="arrivetime" style="width: 30%">
        <a-date-picker show-time placeholder="" v-model="detailParams.arrivetime" style="width: 200px;" :disabled="true"/>
      </a-form-model-item>
      <a-form-model-item v-if="detailParams.eventtype!=902" label="确警时长" prop="timerange" style="width: 30%">
        <a-input v-model="detailParams.timerange" addon-after="分钟" placeholder="" style="width: 200px" :disabled="true"></a-input>
      </a-form-model-item>
      <a-form-model-item v-if="detailParams.eventtype==902&&detailParams.eventcode=='6'" label="设备类型" prop="devicetype" style="width: 30%">
        <a-cascader :disabled="true" :field-names="{ label: 'devicename', value: 'devicecode', children: 'children' }" :options="deviceOptions" v-model="deviceCascaderSelected" :load-data="deviceOnLoadChildren" placeholder="请选择设备类型" :change-on-select="true"></a-cascader>
      </a-form-model-item>
      <a-form-model-item label="报事/报修" prop="reports" style="width: 30%">
        <a-select v-model="detailParams.reports" placeholder="请选择" style="width: 200px;" :disabled="true">
          <a-select-option value="无">无</a-select-option>
          <a-select-option value="报事">报事</a-select-option>
          <a-select-option value="报修">报修</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="报事/报修单号" prop="repairorder" style="width: 30%">
        <a-input v-model="detailParams.repairorder" placeholder="" style="width: 200px" :disabled="true"></a-input>
      </a-form-model-item>
      <a-form-model-item label="计划性作业" prop="plannedwork" style="width: 30%">
        <a-select v-model="detailParams.plannedwork" placeholder="请选择" style="width: 125px" :disabled="true">
          <a-select-option :value="1">是</a-select-option>
          <a-select-option :value="0">否</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="detailParams.eventtype!=902" label="到场反馈" prop="feedback" style="width: 30%">
        <a-select v-model="detailParams.feedback" placeholder="" style="width: 200px" :disabled="true">
          <a-select-option v-for="(item, index) in feedbackList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="detailParams.systemnameid==1" label="原因分类" prop="causeclassify" style="width: 30%">
        <a-select v-model="detailParams.causeclassify" placeholder="" style="width: 200px" :disabled="true">
          <a-select-option v-for="(item, index) in causeClassifyList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="情况描述" prop="description" style="width: 30%">
        <a-textarea v-model="detailParams.description" placeholder="" cols="32" rows="2" :disabled="true"></a-textarea>
      </a-form-model-item>
      <a-form-model-item label="处理措施" prop="measure" style="width: 30%">
        <a-textarea v-model="detailParams.measure" placeholder="" cols="32" rows="2" :disabled="true"></a-textarea>
      </a-form-model-item>
      <a-form-model-item label="记录人" prop="recordman" style="width: 30%">
        <a-input v-model="detailParams.recordman" placeholder="" style="width: 200px" :disabled="true"></a-input>
      </a-form-model-item>
      <a-form-model-item label="记录时间" prop="writetime" style="width: 30%">
        <a-date-picker v-model="detailParams.writetime" show-time placeholder="" :disabled="true" style="width: 200px;"></a-date-picker>
      </a-form-model-item>
      <a-divider />
      <a-form-model-item label="跟进结果" prop="followreturn" style="width: 30%">
        <a-textarea v-model="detailParams.followreturn" placeholder="" cols="32" rows="2" :disabled="true"></a-textarea>
      </a-form-model-item>
      <a-form-model-item label="完成标志" prop="eventstatus" style="width: 30%">
        <a-select default-value="" v-model="detailParams.eventstatus" style="width: 200px" :disabled="true">
          <a-select-option v-for="(item, index) in finishSigns" :key="index" :value="item.key">{{item.value}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="确认人" prop="confirmman" style="width: 30%">
        <a-input v-model="detailParams.confirmman" placeholder="" style="width: 200px" :disabled="true"></a-input>
      </a-form-model-item>
      <a-form-model-item label="确认时间" prop="confirmtime" style="width: 30%">
        <a-date-picker v-model="detailParams.confirmtime" show-time placeholder="" :disabled="true" style="width: 200px;"></a-date-picker>
      </a-form-model-item>
      <a-form-model-item label="处理时长" prop="processtime" style="width: 30%">
        <a-input v-model="detailParams.processtime" addon-after="分钟" placeholder="" :disabled="true" style="width: 200px"></a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import pagination from "@/mixins/pagination";
import {mapGetters, mapState} from "vuex";
import {findAbnormalAnalysisMinRangeList, getEventrecordListByCondition} from "A/wlw";
import moment from "moment";
import {getCache, getItemFromArrayByKey} from "U";
import {finishSigns} from "@/json/wlwhistory";
import deviceselect from "@/mixins/deviceselect";
export default {
  name: "EventRecordDetail",
  mixins: [deviceselect],
  props: {
    visible: {
      default: false
    },
    detailData: {
      default: null
    },
  },
  components: {},
  data() {
    return {
      moment,
      systemNamesMap: [],
      finishSigns,
      systemNames:[],
      alarmTypes:[],
      alarmChildTypes:[],
      feedbackList:[],
      bigInfluences:[],
      causeClassifyList:[],
      deviceCodeList:[],
      deviceCascaderSelected:[],
      modalVisible: false,
      detailParams:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      return '异常情况详情';
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.modalVisible = true;
        let dictionary = getCache('dictionary', true);
        if (dictionary) {
          this.bigInfluences = dictionary.bigInfluences;
          this.alarmTypes = dictionary.alarmTypes;
          this.alarmTypesMap = dictionary.alarmTypesMap;
          this.alarmChildTypes = dictionary.alarmChildTypes;
          this.systemNames = dictionary.systemNames;
          this.systemNamesMap = dictionary.systemNamesMap;
          this.feedbackList = dictionary.feedbackList;
          this.abnormalTypes = dictionary.abnormalTypes;
          this.abnormalTypesMap = dictionary.abnormalTypesMap;
          this.equipmentTypes = dictionary.equipmentTypes;
          this.deviceCodeList = dictionary.deviceCodeList;
          this.manageTypes = dictionary.manageTypes;
          this.recordTypes = dictionary.recordTypes;
          this.causeClassifyList = dictionary.causeClassifyList;
        }
        this.getTableData();
      }
    },
    modalVisible(val) {
      if (!val) {
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    getTableData() {
      this.tableLoading = true;
      let params = {
        eventrecordid:this.detailData.eventrecordid
      };
      getEventrecordListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.detailParams=res.item[0]
          this.detailParams.eventstarttime=this.detailParams.eventstarttime?moment(this.detailParams.eventstarttime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
          this.detailParams.confirmtime=this.detailParams.confirmtime?moment(this.detailParams.confirmtime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
          this.detailParams.writetime=this.detailParams.writetime?moment(this.detailParams.writetime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
          this.detailParams.arrivetime=this.detailParams.arrivetime?moment(this.detailParams.arrivetime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
          this.detailParams.biginfluence=this.detailParams.biginfluence?this.detailParams.biginfluence.toString():null
          this.detailParams.systemnameid=this.detailParams.systemnameid?this.detailParams.systemnameid.toString():null
          this.detailParams.feedback=this.detailParams.feedback?this.detailParams.feedback.toString():null
          this.detailParams.causeclassify=this.detailParams.causeclassify?this.detailParams.causeclassify.toString():null
          if (this.detailParams.eventtype===902&&this.detailParams.eventcode==="6") {
            this.initDeviceOptionsByDevicecode(this.detailParams.devicecode)
          }else{
            this.deviceCascaderSelected=[]
          }
        }
      })
    },
  },
}
</script>
<style scoped>

</style>