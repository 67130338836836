export const orderSteps = {
  '10': '待管理员派单',
  '20': '待组长派单',
  '30': '待组员派单',
  '40': '待组员转单',
  '50': '待签到',
  '60': '待维保',
  '70': '待组长审核',
  '80': '待物业审核',
  '11': '管理员接单完成',
  '21': '组长接单完成',
  '31': '组员接单完成',
  '41': '组员转单完成',
  '51': '签到完成',
  '61': '维保完成',
  '71': '完成',
}

export const orderStepColor = {
  '10': 'pink',
  '20': 'pink',
  '30': 'pink',
  '40': 'pink',
  '50': 'orange',
  '60': 'orange',
  '70': 'orange',
  '80': 'blue',
  '11': 'pink',
  '21': 'pink',
  '31': 'pink',
  '41': 'pink',
  '51': 'orange',
  '61': 'blue',
  '71': 'green',
}

export const orderStepList = [
  { value: '10', name: '待管理员派单'},
  { value: '20', name: '待组长派单'},
  { value: '30', name: '待组员派单'},
  { value: '40', name: '待组员转单'},
  { value: '50', name: '待签到'},
  { value: '60', name: '待维保'},
  { value: '70', name: '待组长审核'},
  { value: '71', name: '已完成'},
]

export const orderStepMap = {
  '11': '维保管理接单',
  '21': '维保组长接单',
  '31': '维保组员接单',
  '41': '维保组员转单',
  '51': '签到完成',
  '61': '维保完成',
  '71': '完成',
}

export const orderStatus = {
  '1': '正常',
  '2': '异常',
  '3': '撤销',
}

export const resultCode = {
  '1': '正常',
  '2': '保养后正常',
  '3': '报修（带病运行）',
  '4': '报修（停止运行）',
}

export const gradeList = {
  '1': '不合格',
  '2': '合格',
  '3': '良好',
  '4': '优秀',
}