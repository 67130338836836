// 报事报修
import store from '@/store'
import http from '@/utils/http'

let preApi = '';
if(store.state.mock && process.env.NODE_ENV == 'development') {
  preApi = '/mock';
}

//添加报事报修记录
export function addRepairsOrder(params) {
  return http({
    url: `${preApi}/repairsreport/addRepairsOrder`,
    method: "post",
    data: params
  })
}
export function splitRepairsOrder(params) {
  return http({
    url: `${preApi}/repairsreport/splitRepairsOrder`,
    method: "post",
    data: params
  })
}
//删除报事报修记录
export function deleteRepairsOrder(params) {
  return http({
    url: `${preApi}/repairsreport/deleteRepairsOrder`,
    method: "post",
    data: params
  })
}
//修改报事报修记录
export function modifyRepairsOrder(params) {
  return http({
    url: `${preApi}/repairsreport/modifyRepairsOrder`,
    method: "post",
    data: params,
  })
}

//获取报事报修计划列表查询条件
export function getRepairsOrderListByCondition(params) {
  return http({
    url: `${preApi}/repairsreport/getRepairsOrderListByCondition`,
    method: "post",
    data: params,
  })
}
export function getRepairsOrderDetailListByCondition(params) {
  return http({
    url: `${preApi}/repairsreport/getRepairsOrderDetailListByCondition`,
    method: "post",
    data: params,
  })
}
export function getMinOrMaxRepairsOrder(params) {
  return http({
    url: `${preApi}/repairsreport/getMinOrMaxRepairsOrder`,
    method: "post",
    data: params,
  })
}
//获取报事报修我的待办
export function getMyToDoOrder(params) {
  return http({
    url: `${preApi}/repairsreport/getMyToDoOrder`,
    method: "post",
    data: params,
  })
}
//添加维修计划信息
export function addRepairSchedule(params) {
  return http({
    url: `${preApi}/repairsreport/addRepairSchedule`,
    method: "post",
    data: params
  })
}
//删除维修计划信息
export function deleteRepairSchedule(params) {
  return http({
    url: `${preApi}/repairsreport/deleteRepairSchedule`,
    method: "post",
    data: params
  })
}
//修改维修计划信息
export function modifyRepairSchedule(params) {
  return http({
    url: `${preApi}/repairsreport/modifyRepairSchedule`,
    method: "post",
    data: params,
  })
}

//获取维修计划列表查询条件
export function getRepairScheduleConditionList(params) {
  return http({
    url: `${preApi}/repairsreport/getRepairScheduleConditionList`,
    method: "post",
    data: params,
  })
}
//根据条件获取维修计划列表
export function getRepairScheduleListByCondition(params) {
  return http({
    url: `${preApi}/repairsreport/getRepairScheduleListByCondition`,
    method: "post",
    data: params,
  })
}
//根据条件获取维修计划编号
export function getOrderNums(params) {
  return http({
    url: `${preApi}/repairsreport/getOrderNums`,
    method: "post",
    data: params,
  })
}
//根据条件获取专属维修条目
export function getExclusiveRepair(params) {
  return http({
    url: `${preApi}/repairsreport/getExclusiveRepair`,
    method: "post",
    data: params,
  })
}
//根据条件获取维修计划对照表列表
export function getRepairScheduleContrastListByCondition(params) {
  return http({
    url: `${preApi}/repairsreport/getRepairScheduleContrastListByCondition`,
    method: "post",
    data: params,
  })
}
//导出维修计划excel
export function exportRepairScheduleExcel(params) {
  return http({
    url: `${preApi}/repairsreport/exportRepairScheduleExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

//根据条件查询所有可以处理工单的人员列表
export function getAllOrderUserByMonitorPointNum(params) {
  return http({
    url: `${preApi}/repairsreport/getAllOrderUserByMonitorPointNum`,
    method: "post",
    data: params,
  })
}

//导出报事报修计划excel
export function exportRepairsOrderExcel(params) {
  return http({
    url: `${preApi}/repairsreport/exportRepairsOrderExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

// 查询工单详情
export function getRepairsOrderInfoListByCondition(params) {
  return http({
    url: `${preApi}/repairsreport/getRepairsOrderInfoListByCondition`,
    method: "post",
    data: params
  })
}
// 查询工单详情
export function getRepairsOrderAnalysisByCondition(params) {
  return http({
    url: `${preApi}/repairsreport/getRepairsOrderAnalysisByCondition`,
    method: "post",
    data: params
  })
}
//导出报事报修处理分析报表excel
export function exportRepairsOrderInfoExcel(params) {
  return http({
    url: `${preApi}/repairsreport/exportRepairsOrderInfoExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}
// 查询工单详情
export function getRepairsOrderInfoByOrderId(params) {
  return http({
    url: `${preApi}/repairsreport/getRepairsOrderInfoByOrderId`,
    method: "post",
    data: params
  })
}
// 查询工单图片详情
export function getRepairsOrderPicByOrderId(params) {
  return http({
    url: `${preApi}/repairsreport/getRepairsOrderPicByOrderId`,
    method: "post",
    data: params
  })
}

//添加报事报修记录
export function addResidentInfo(params) {
  return http({
    url: `${preApi}/repairsreport/addResidentInfo`,
    method: "post",
    data: params
  })
}
//删除报事报修记录
export function deleteResidentInfo(params) {
  return http({
    url: `${preApi}/repairsreport/deleteResidentInfo`,
    method: "post",
    data: params
  })
}
//修改报事报修记录
export function modifyResidentInfo(params) {
  return http({
    url: `${preApi}/repairsreport/modifyResidentInfo`,
    method: "post",
    data: params,
  })
}
//获取报事报修计划列表查询条件
export function getResidentInfoListByCondition(params) {
  return http({
    url: `${preApi}/repairsreport/getResidentInfoListByCondition`,
    method: "post",
    data: params,
  })
}
//根据项目编号获取管家列表
export function getHousekeeperByMonitorPointNum(params) {
  return http({
    url: `${preApi}/repairsreport/getHousekeeperByMonitorPointNum`,
    method: "post",
    data: params,
  })
}

// 提交工单流程
export function commitWorkflow(params) {
  return http({
    url: `${preApi}/repairsreport/commitWorkflow`,
    method: "post",
    data: params
  })
}
// 查询工单流程
export function getWorkflowByOrderId(params) {
  return http({
    url: `${preApi}/repairsreport/getWorkflowByOrderId`,
    method: "post",
    data: params
  })
}
// 提交报事报修图片信息列表
export function commitRepairsPic(params) {
  return http({
    url: `${preApi}/repairsreport/commitRepairsPic`,
    method: "post",
    data: params
  })
}


// 维护组列表查询
export function getRepairsGroupListByCondition(params) {
  return http({
    url: `${preApi}/repairsgroup/getRepairsGroupListByCondition`,
    method: "post",
    data: params
  })
}
// 新增维护组
export function addRepairsGroup(params) {
  return http({
    url: `${preApi}/repairsgroup/addRepairsGroup`,
    method: "post",
    data: params
  })
}
// 修改维护组
export function modifyRepairsGroup(params) {
  return http({
    url: `${preApi}/repairsgroup/modifyRepairsGroup`,
    method: "post",
    data: params
  })
}
// 维护组删除
export function deleteRepairsGroup(params) {
  return http({
    url: `${preApi}/repairsgroup/deleteRepairsGroup`,
    method: "post",
    data: params
  })
}
// 查询维护组详情
export function getRepairsGroupById(params) {
  return http({
    url: `${preApi}/repairsgroup/getRepairsGroupById`,
    method: "post",
    data: params
  })
}
// 绑定维护组成员
export function bindRepairsMember(params) {
  return http({
    url: `${preApi}/repairsgroup/bindRepairsMember`,
    method: "post",
    data: params
  })
}
//根据维保组成员id查询维保组
export function getRepairsGroupByUserId(params) {
  return http({
    url: `${preApi}/repairsgroup/getRepairsGroupByUserId`,
    method: "post",
    data: params
  })
}


//添加专属维修条目信息
export function addExclusiveRepair(params) {
  return http({
    url: `${preApi}/repairsreport/addExclusiveRepair`,
    method: "post",
    data: params
  })
}
//删除专属维修条目信息
export function deleteExclusiveRepair(params) {
  return http({
    url: `${preApi}/repairsreport/deleteExclusiveRepair`,
    method: "post",
    data: params
  })
}
//修改专属维修条目信息
export function modifyExclusiveRepair(params) {
  return http({
    url: `${preApi}/repairsreport/modifyExclusiveRepair`,
    method: "post",
    data: params,
  })
}
//获取专属维修条目列表查询条件
export function getExclusiveRepairConditionList(params) {
  return http({
    url: `${preApi}/repairsreport/getExclusiveRepairConditionList`,
    method: "post",
    data: params,
  })
}
//根据条件获取专属维修条目列表
export function getExclusiveRepairListByCondition(params) {
  return http({
    url: `${preApi}/repairsreport/getExclusiveRepairListByCondition`,
    method: "post",
    data: params,
  })
}
//导出专属维修条目excel
export function exportExclusiveRepairExcel(params) {
  return http({
    url: `${preApi}/repairsreport/exportExclusiveRepairExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}